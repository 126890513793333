<template>
  <div class="glyh-mask" v-if="glyhModal">
    <div class="glyh-content">
      <div class="glyh-header"></div>
      <div class="glyh-body">
        <div class="tip">
          该生可能与以下学生是多胞胎，且选报了同一所意向学校，请确定是否进行多胞胎关联派位。
        </div>
        <div class="glyh-list">
          <div v-for="item in glyhList" :key="item.id">
            <span>{{ item.stuXm }}：</span><span>{{ item.stuZjh }}</span>
          </div>
        </div>
        <div class="tip">
          重要提示：您选择确定关联派位后，多人将视为一人参加派位，成功则多人均被录取，未成功则多人均未被录取；若放弃关联派位，则视为多人分别独立参加派位。
        </div>
      </div>
      <div class="glyh-footer">
        <a-space size="large">
          <a-button
            type=""
            shape="round"
            size="large"
            block
            class=""
            @click="glyhCancel"
            >放弃关联派位</a-button
          >
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            class=""
            @click="glyhConfirm"
            >确定关联派位</a-button
          >
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "glyh",
  props: {
    serial: String,
  },
  data() {
    return {
      glyhModal: false,
      glRegIds: [],
      glyhList: [],
    };
  },
  methods: {
    // 关联派位 -- 取消
    glyhCancel() {
      this.glyhModal = false;
      this.$emit("abandon");
    },
    // 关联派位 -- 确定
    glyhConfirm() {
      this.glyhModal = false;
      this.glConfirm = true;

      let arr = [];
      this.glyhList.forEach((item) => {
        arr.push(item.regId);
      });

      this.glRegIds = arr.toString();
      this.$emit("getGlyhIds", this.glRegIds, this.glyhList);
    },
    show(list) {
      this.glyhModal = true;
      this.glyhList = list;
    },
  },
};
</script>

<style lang="less" scoped>
.glyh-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.glyh-content {
  width: 50%;
  margin: 100px auto 0;
  padding: 30px 60px;
  background: #fff;
}
.glyh-list {
  margin: 30px 0;
  background: #e9e9e9;

  div {
    padding: 10px 20px;
  }
}
.glyh-footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.tip {
  color: #ff7575;
  font-size: 14px;
}
</style>
